import { Route, Routes } from "react-router-dom";
import EditingMap from "./EditingMap";
import TilesetSelection from "./TilesetSelection";

const App: React.FC = () => {
  return <Routes>
    <Route path="/" element={<TilesetSelection />} />
    <Route path="/tilesets/:tilesetName" element={<EditingMap />} />
  </Routes>;
};

export default App;
