import React, { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

const TilesetSelection: React.FC = () => {
  const [apiKey, setApiKey] = useState(localStorage.getItem('tsed.apiKey') || '');
  const [apiKeySecret, setApiKeySecret] = useState(localStorage.getItem('tsed.apiKeySecret') || '');

  const navigate = useNavigate();

  const onSubmit = useCallback<React.FormEventHandler<HTMLFormElement>>((ev) => {
    ev.preventDefault();
    const tilesetNameEl = ev.currentTarget.elements.namedItem('tilesetName');
    if (tilesetNameEl) {
      const tilesetName = (tilesetNameEl as HTMLInputElement).value;
      if (tilesetName) {
        navigate(`/tilesets/${tilesetName}`);
      }
    }
  }, [navigate]);

  useEffect(() => {
    localStorage.setItem('tsed.apiKey', apiKey);
    localStorage.setItem('tsed.apiKeySecret', apiKeySecret);
  }, [apiKey, apiKeySecret]);

  return (
    <form className="px-4" onSubmit={onSubmit}>
      <label className="block my-2">
        <span className="text-gray-700">API Key</span>
        <input
          type="text"
          name="apiKey"
          value={apiKey}
          onChange={(x) => setApiKey(x.currentTarget.value)}
          className="
            mt-1
            block
            w-full
            rounded-md
            border-gray-300
            shadow-sm
            focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50
          "
        />
      </label>

      <label className="block my-2">
        <span className="text-gray-700">API Key Secret</span>
        <input
          type="text"
          name="apiKeySecret"
          value={apiKeySecret}
          onChange={(x) => setApiKeySecret(x.currentTarget.value)}
          className="
            mt-1
            block
            w-full
            rounded-md
            border-gray-300
            shadow-sm
            focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50
          "
        />
      </label>

      <label className="block my-2">
        <span className="text-gray-700">タイルセット名</span>
        <input type="text" name="tilesetName" className="
          mt-1
          block
          w-full
          rounded-md
          border-gray-300
          shadow-sm
          focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50
        " />
      </label>

      <button
        type="submit"
        className="inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
      >編集する</button>
    </form>
  )
}

export default TilesetSelection;
