import { GeoloniaMap } from '@geolonia/embed-react';
import { Buffer } from 'buffer';
import type maplibregl from 'maplibre-gl';
import MapboxDraw from "@mapbox/mapbox-gl-draw";
import '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css';
import React, { useCallback, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import area from '@turf/area';
import length from '@turf/length';

const backgroundColor = 'rgba(255, 0, 0, 0.4)';
const clusterColor = '#ff0000';
const strokeColor = '#FFFFFF';

const headersWithAuth = (headers: {[key: string]: string}) => {
  const h = new Headers(headers);
  h.set('authorization', 'Basic ' + Buffer.from(
    localStorage.getItem('tsed.apiKey') +
    ':' +
    localStorage.getItem('tsed.apiKeySecret'),
    'utf-8'
  ).toString('base64'));
  return h;
}

const FeatureEditorPane: React.FC<{
  feature: GeoJSON.Feature,
  updateFeature: (updater: (feature: GeoJSON.Feature) => GeoJSON.Feature) => void,
}> = ({ feature, updateFeature }) => {
  const newPropNameRef = useRef<HTMLInputElement | null>(null);
  const newPropValueRef = useRef<HTMLInputElement | null>(null);

  const addProp = useCallback<React.MouseEventHandler<HTMLButtonElement>>((ev) => {
    ev.preventDefault();
    if (!newPropNameRef.current || !newPropValueRef.current) { return; }
    const name = newPropNameRef.current.value;
    const value = newPropValueRef.current.value;

    updateFeature((feature) => {
      return {
        ...feature,
        properties: {
          ...feature.properties,
          [name]: value,
        }
      };
    });

    newPropNameRef.current.value = '';
    newPropValueRef.current.value = '';
  }, [updateFeature]);

  const updateProp = useCallback<React.FocusEventHandler<HTMLInputElement>>((event) => {
    const name = event.currentTarget.dataset.propKey || '';
    const newValue = event.currentTarget.value;
    updateFeature((feature) => {
      return {
        ...feature,
        properties: {
          ...feature.properties,
          [name]: newValue,
        }
      };
    });
  }, [updateFeature]);

  const deleteProp = useCallback<React.MouseEventHandler<HTMLButtonElement>>((event) => {
    event.preventDefault();
    const name = event.currentTarget.dataset.propKey || '';
    updateFeature((feature) => {
      const properties = {...feature.properties};
      delete properties[name];
      return {
        ...feature,
        properties,
      };
    });
  }, [updateFeature]);

  return <div className='absolute inset-y-0 w-4/12 bg-white overflow-y-auto z-10'>
    <table className='table-auto w-full'>
      <tbody>
        <tr>
          <th>ID</th>
          <td className='break-words'>{feature.id}</td>
        </tr>
        <tr>
          <th>Geom</th>
          <td className='break-words'>{feature.geometry.type}</td>
        </tr>
        { (feature.geometry.type === 'Polygon' || feature.geometry.type === 'MultiPolygon') && <tr>
          <th>Area</th>
          <td className='break-words'>{area(feature)}m²</td>
        </tr> }
        { (feature.geometry.type === 'LineString' || feature.geometry.type === 'MultiLineString') && <tr>
          <th>Length</th>
          <td className='break-words'>{length(feature)}km</td>
        </tr> }
      </tbody>
    </table>

    <hr />

    <h5 className='text-center font-medium'>Properties</h5>

    <table className='table-auto w-full'>
      <thead>
        <tr>
          <th>Name</th>
          <th>Value</th>
          <td></td>
        </tr>
      </thead>
      <tbody>
        {Object.entries(feature.properties || {}).sort((a, b) => a[0].localeCompare(b[0])).map(([key, value]) => <tr key={feature.id + '-' + key}>
          <td><code>{key}</code></td>
          <td>
            <input
              type="text"
              defaultValue={value}
              data-prop-key={key}
              onBlur={updateProp}
            />
          </td>
          <td>
            <button
              type="button"
              data-prop-key={key}
              onClick={deleteProp}
              className='inline-flex items-center px-2.5 py-1.5 border border-transparent text-xs font-medium rounded text-indigo-700 bg-indigo-100 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
            >
              -
            </button>
          </td>
        </tr>)}

        <tr>
          <td>
            <input
              type="text"
              className='w-full'
              ref={newPropNameRef}
            />
          </td>
          <td>
          <input
              type="text"
              className='w-full'
              ref={newPropValueRef}
            />
          </td>
          <td>
            <button
              type="button"
              onClick={addProp}
              className='inline-flex items-center px-2.5 py-1.5 border border-transparent text-xs font-medium rounded shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
            >
              +
            </button>
          </td>
        </tr>
      </tbody>
    </table>
  </div>;
};

const EditingMap: React.FC = () => {
  const { tilesetName } = useParams();
  const navigate = useNavigate();
  const [ draw, setDraw ] = useState<MapboxDraw | undefined>(undefined);
  const [ selectedFeature, setSelectedFeature ] = useState<GeoJSON.Feature | undefined>(undefined);
  const currentSelFeature = useRef<GeoJSON.Feature | undefined>(undefined);

  const onLoad = useCallback((map: maplibregl.Map) => {
    map.on('load', () => {
      map.removeLayer('poi-r25');
      map.removeLayer('poi-r10-r24');
      map.removeLayer('poi-r0-r9');
      map.removeLayer('poi-railway');
    });

    const draw = new MapboxDraw();
    map.addControl(draw as any, 'top-right');

    const loadCurrentView = async () => {
      const currentBounds = map.getBounds().toArray().flat();
      const url = new URL(`${process.env.REACT_APP_BASE_URL}/${tilesetName}/features`);
      url.searchParams.set('bbox', currentBounds.join(','));
      const resp = await fetch(url.toString(), {
        headers: headersWithAuth({}),
      });
      if (!resp.ok) {
        alert(`「${tilesetName}」が存在しません`);
        navigate('/');
        return;
      }
      const json = await resp.json();
      draw.add(json);
    };

    const setSelection = (features: GeoJSON.Feature[]) => {
      if (features.length > 1 || features.length === 0) {
        setSelectedFeature(undefined);
        currentSelFeature.current = undefined;
        return;
      }
      setSelectedFeature(features[0] as GeoJSON.Feature);
      currentSelFeature.current = features[0];
    };

    map.on('draw.create', async (e) => {
      console.log('draw.create', e);
      for (const feature of e.features) {
        const resp = await fetch(`${process.env.REACT_APP_BASE_URL}/${tilesetName}/features`, {
          method: 'POST',
          body: JSON.stringify({...feature, id: undefined}),
          headers: headersWithAuth({ 'content-type': 'application/json' }),
        });
        const json = await resp.json();
        draw.delete(feature.id);
        draw.add(json);
        setSelection([json]);
      }
    });

    map.on('draw.delete', async (e) => {
      console.log('draw.delete', e);
      for (const feature of e.features) {
        await fetch(`${process.env.REACT_APP_BASE_URL}/${tilesetName}/features/${feature.id}`, {
          method: 'DELETE',
          body: JSON.stringify({...feature, id: undefined}),
          headers: headersWithAuth({ 'content-type': 'application/json' }),
        });
      }
      setSelection([]);
    });

    const combineUncombineHandler = async (e: any) => {
      for (const feature of e.deletedFeatures) {
        await fetch(`${process.env.REACT_APP_BASE_URL}/${tilesetName}/features/${feature.id}`, {
          method: 'DELETE',
          body: JSON.stringify({...feature, id: undefined}),
          headers: headersWithAuth({ 'content-type': 'application/json' }),
        });
      }

      for (const feature of e.createdFeatures) {
        const resp = await fetch(`${process.env.REACT_APP_BASE_URL}/${tilesetName}/features`, {
          method: 'POST',
          body: JSON.stringify({...feature, id: undefined}),
          headers: headersWithAuth({ 'content-type': 'application/json' },)
        });
        const json = await resp.json();
        draw.add(json);
        draw.delete(feature.id);
      }
    };

    map.on('draw.combine', combineUncombineHandler);
    map.on('draw.uncombine', combineUncombineHandler);

    map.on('draw.update', async (e) => {
      console.log('draw.update', e);
      for (const feature of e.features) {
        await fetch(`${process.env.REACT_APP_BASE_URL}/${tilesetName}/features/${feature.id}`, {
          method: 'PATCH',
          body: JSON.stringify({...feature, id: undefined}),
          headers: headersWithAuth({ 'content-type': 'application/json' }),
        });
      }
    });

    map.on('draw.selectionchange', ({features}) => {
      setSelection(features);
    });

    map.on('moveend', () => {
      loadCurrentView();
    });
    loadCurrentView();

    setDraw(draw);
  }, [navigate, tilesetName]);

  const updateFeature = useCallback(async (updater: (feature: GeoJSON.Feature) => GeoJSON.Feature) => {
    if (!currentSelFeature.current || !draw) return;
    const newFeature = updater(currentSelFeature.current);
    draw.add(newFeature);
    await fetch(`${process.env.REACT_APP_BASE_URL}/${tilesetName}/features/${newFeature.id}`, {
      method: 'PATCH',
      body: JSON.stringify({...newFeature, id: undefined}),
      headers: headersWithAuth({ 'content-type': 'application/json' }),
    });
    setSelectedFeature(newFeature);
    currentSelFeature.current = newFeature;
  }, [draw, tilesetName]);

  const onLoadViewer = useCallback((map: maplibregl.Map) => {
    map.on('load', () => {
      map.removeLayer('poi-r25');
      map.removeLayer('poi-r10-r24');
      map.removeLayer('poi-r0-r9');
      map.removeLayer('poi-railway');

      map.addSource('mydata', {
        type: 'vector',
        url: `${process.env.REACT_APP_TILESERVER_URL}/dynamictiles/${tilesetName}/tiles.json?key=${localStorage.getItem('tsed.apiKey')}`
      });

      map.addLayer({
        "id": "mydata-circle",
        "type": "circle",
        "source": "mydata",
        "source-layer": "default",
        "filter": ["all", ["==", "$type", "Point"], ["!=", "clustered", true]],
        "layout": {"visibility": "visible"},
        "paint": {
          'circle-radius': [
            'case',
            ['==', 'small', ['get', 'marker-size']], 7,
            ['==', 'large', ['get', 'marker-size']], 13,
            9,
          ],
          'circle-color': ['string', ['get', 'marker-color'], backgroundColor],
          'circle-opacity': ['number', ['get', 'fill-opacity'], 1.0],
          'circle-stroke-width': ['number', ['get', 'stroke-width'], 1],
          'circle-stroke-color': ['string', ['get', 'stroke'], strokeColor],
          'circle-stroke-opacity': ['number', ['get', 'stroke-opacity'], 1.0],
        },
      });

      map.addLayer({
        id: 'mydata-circle-name',
        type: 'symbol',
        source: 'mydata',
        "source-layer": 'default',
        filter: ["all", ["==", "$type", "Point"], ['!=', 'clustered', true]],
        layout: {
          'text-field': '{name}',
          'text-size': 14,
          'text-font': ['Noto Sans Regular'],
          'text-anchor': 'left',
          'text-offset': [1, -0.1],
        },
      });

      map.addLayer({
        id: 'mydata-circle-clusters',
        type: 'circle',
        source: 'mydata',
        "source-layer": 'default',
        filter: ["all", ["==", "$type", "Point"], ['==', 'clustered', true], ['has', 'count']],
        paint: {
          'circle-radius': 20,
          'circle-color': clusterColor,
          'circle-opacity': 1.0,
        },
      });

      map.addLayer({
        id: 'mydata-circle-clusters-count',
        type: 'symbol',
        source: 'mydata',
        "source-layer": 'default',
        filter: ["all", ["==", "$type", "Point"], ['==', 'clustered', true], ['has', 'count']],
        layout: {
          'text-field': '{count}',
          'text-size': 14,
          'text-font': ['Noto Sans Regular'],
        },
      });

      map.addLayer({
        "id": "mydata-line",
        "type": "line",
        "source": "mydata",
        "source-layer": "default",
        "filter": ["all", ["==", "$type", "LineString"]],
        "layout": {"visibility": "visible"}
      });

      map.addLayer({
        "id": "mydata-polygon",
        "type": "fill",
        "source": "mydata",
        "source-layer": "default",
        "filter": ["all", ["==", "$type", "Polygon"]],
        "paint": {
          "fill-color": "rgba(145, 192, 214, 0.36)",
          "fill-outline-color": "rgba(63, 85, 96, 0.54)"
        }
      });

      map.on('click', (e) => {
        const features = map.queryRenderedFeatures(e.point);
        console.log(features.map(f => f.properties));
      });
    });

    map.showTileBoundaries = true;
  }, [tilesetName]);

  return (
    <div className="w-full h-screen">
      <div className="h-1/2 w-full relative">
        <GeoloniaMap
          apiKey="YOUR-API-KEY"
          style={{height: "100%", width: "100%"}}
          lat="35.681236"
          lng="139.767125"
          marker='off'
          zoom="16"
          gestureHandling='off'
          onLoad={onLoad}
        />
        { selectedFeature && <FeatureEditorPane
          feature={selectedFeature}
          updateFeature={updateFeature}
        /> }
      </div>
      <div className="h-1/2 w-full">
        <GeoloniaMap
          apiKey="YOUR-API-KEY"
          style={{height: "100%", width: "100%"}}
          lat="35.681236"
          lng="139.767125"
          marker='off'
          zoom="16"
          gestureHandling='off'
          onLoad={onLoadViewer}
        />
      </div>
    </div>
  );
}

export default EditingMap;
